<template>
	<b-card>
		<b-row>
			<b-col sm="12">
				<b-tabs pills>
					<b-tab lazy>
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Detail</span>
            </template>
						<edit-user />
					</b-tab>
					<b-tab lazy>
            <template #title>
              <feather-icon
                icon="InfoIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Activity</span>
            </template>
						<activity-user />
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
} from "bootstrap-vue";
import EditUser from "./EditUser.vue";
import ActivityUser from "./ActivityUser.vue";

export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BTabs,
    EditUser,
    ActivityUser,
  },
  props: ["profile", "detail"],
  data() {
    return {};
  },
  methods: {},
  mounted() {
    // console.log('main allthis.detail', this.detail)
  },
};
</script>
<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style>
