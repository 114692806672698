<template>
    <div class="mt-1">
        <b-row>
            <b-col class="mb-1">

                <b-row>
                    <b-col cols="10">
                        <b-row> 
                            <b-col md="4">
                                <b-form-group
                                    label-cols="10"
                                    label-cols-lg="3"
                                    label="Usage :"
                                    label-for="search-usage"
                                >
                                    <!-- <b-form-select
                                        v-model="usageSelected"
                                        :options="usageOptions"
                                        v-on:change="getSelectedUsage"
                                    /> -->
                                    <v-select 
                                        id="search-usage"
                                        v-on:input="getSelectedUsage(`${usageSelected.value}`)"
                                        v-model="usageSelected" 
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
                                        :options="usageOptions" 
                                        class="select-size-sm" 
                                        style="margin-top:-2px; background:white" 
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    label-cols="10"
                                    label-cols-lg="3"
                                    label="Province :"
                                    label-for="search-province"
                                >
                                    <!-- <b-form-select
                                        v-model="propinsiSelected"
                                        :options="propinsiOptions"
                                        v-on:change="getSelectedPropinsi"
                                    /> -->
                                    <v-select 
                                        id="search-province" 
                                        v-on:input="getSelectedPropinsi(`${propinsiSelected.value}`)"
                                        v-model="propinsiSelected" 
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
                                        :options="propinsiOptions" 
                                        class="select-size-sm" 
                                        style="margin-top:-2px; background:white" 
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    label-cols="10"
                                    label-cols-lg="3"
                                    label="Search :"
                                    label-for="search-search"
                                >
                                    <b-form-input
                                        id="search-search"
                                        v-model="searchText"
                                        placeholder="Search"
                                        type="text"
                                        class="d-inline-block"
                                        v-on:keyup="isStationID"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="2">
                        <b-row>
                            <b-col cols="4">
                                <b-button
                                    variant="primary"
                                    class="btn-icon"
                                    v-on:click="searchMetadata"
                                    :disabled="!enabledSearch"
                                >
                                    <feather-icon icon="SearchIcon" />
                                </b-button>
                            </b-col>
                            <b-col cols="8" class="align-self-center">
                                <b-form-checkbox
                                    v-model="allSelected"
                                    value="1"
                                    class="custom-control-primary"
                                    @click.native.prevent="onCheckedAllClick()"
                                    >
                                    Check All
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        
                    </b-col>
                    <!-- <b-col cols="1">
                        <b-form-checkbox
                            v-model="selected"
                            value="D"
                            class="custom-control-warning"
                            >
                            Check All
                        </b-form-checkbox>
                    </b-col> -->
                </b-row>
                
                <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :rtl="direction"
                    :isLoading="isLoading"
                    :search-options="{
                        enabled: true,
                        externalQuery: searchTerm,
                    }"
                    :pagination-options="{
                        enabled: true,
                        perPage:pageLength,
                        setCurrentPage: paginationCurrentPage,
                    }"
                    @on-page-change="onPageChange"
                    theme="polar-bear"
                    
                >
                    <template
                        slot="table-row"
                        slot-scope="props"
                    >
                        <template v-if="props.column.field === 'checked'">
                            <b-form-checkbox v-model="props.row.checked" @change="onCheckedClick($event, props.row)"/>
                        </template>

                        <!-- <span
                            v-else-if="props.column.field === 'station_wmo_id'"
                            class="text-nowrap"
                        >
                            <span>{{ props.row.station_wmo_id }}</span>
                        </span> -->
                        <span v-else-if="props.column.field === 'station_wmo_id'" class="text-nowrap">
                            <a v-if="searchByUsage" href="#">{{ props.row.usage_station_id }}</a>
              
                            <a v-else-if="props.row.station_wmo_id == 0" href="#">{{ props.row.is_poshujan ? props.row.pos_hujan_id : props.row.station_id }}</a>

                            <a v-else-if="props.row.station_wmo_id != 0" href="#">{{ props.row.station_wmo_id }}</a>

                            <a v-else href="#">{{ props.row.station_wmo_id }}</a>
                        </span>

                    </template> 
                </vue-good-table>

            </b-col>
        </b-row>
    </div>
</template>


<script>
    import {
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BInputGroup,
        BFormInput,
        BButton,
        BFormCheckbox
    } from 'bootstrap-vue'
    
    import metadata from "@/api/metadata";
    import Service from "@/api/userservice";
    import { VueGoodTable } from 'vue-good-table'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import vSelect from "vue-select";
    export default {
        components: {
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BInputGroup,
            BFormInput,
            BButton,
            BFormCheckbox,
            vSelect,
            VueGoodTable,
        },
        data() {
            return {
                showLoading: false,
                isLoading: true,
                dataUser: '',
                userGroups: [],
                dir: false,
                userType:'root',
                paramsStation : '',
                paginationCurrentPage: 1,
                pageLength: 10,
                ids:'',
                searchTerm: '',
                searchText: '',
                searchByUsage: false,
                allSelected: 0,
                rows: [],
                rowsSelected: [],
                columns: [
                    {
                        label: 'Station ID',
                        field: 'station_wmo_id',
                        tdClass: 'text-center',
                        thClass: 'text-center theadc',
                    },
                    {
                        label: 'Station Name',
                        field: 'station_name',
                        tdClass: 'text-left',
                        thClass: 'text-center theadc',
                    },
                    {
                        label: 'Province',
                        field: 'propinsi_name',
                        tdClass: 'text-center',
                        thClass: 'text-center theadc',
                    },
                    {
                        label: 'Checked',
                        field: 'checked',
                        tdClass: 'd-flex flex-wrap justify-content-center',
                        thClass: 'text-center theadc',
                    }
                ],
                propinsiSelected: '',
                propinsiOptions: [
                    { value: '', text: '-' }
                ],
                usageSelected: '',
                usageOptions: [
                    { value: '', text: '-' },
                    { value: 'is_mkg', text: 'MKG' },
                    { value: 'is_poshujan', text: 'Pos Hujan' },
                    { value: 'is_kualitas_udara', text: 'Kualitas Udara' },
                    { value: 'is_peralatan_otomatis', text: 'AWS Center' },
                    { value: 'is_radar', text: 'Radar' },
                    { value: 'is_percepatan_tanah', text: 'Percepatan Tanah' },
                    { value: 'is_gempa', text: 'Gempa' },
                    { value: 'is_bmkgentry', text: 'BMKG Entry' },
                ],
                enabledSearch: false
            };
        },
        
        mounted() {
            let userID = this.$route.params.userId;
            this.getDetailUser(userID)
            this.listAllPropinsi();

            this.usageOptions = [{ value: '', text: '-' }]
            this.listManageUsage();
            
            this.rows = [];
            this.rowsSelected = []
            this.paramsStation = ''
            this.listAllStasiun(1, 200, this.paramsStation, this.userType, this.ids, false);
            
        },

        methods: {

            // async listManageUsage() {
            //     try {
            //         const { data } = await metadata.getListManageUsage();

            //         for (var i = 0; i < data.items.length; i++) {
            //             const row = {
            //                 value: data.items[i].manage_usage_name,
            //                 text: data.items[i].manage_usage_name
            //             }
            //             this.usageOptions.push(row)
                        
            //         }
                    
            //     } catch (e) {
            //         console.log(e);
            //     }
            // },

            async listManageUsage() {
                try {
                    const { data } = await metadata.getListManageUsage();
                    this.usageOptions = [];
                    let usagesList = data.items.sort((a, b) => {
                                        let fa = a.manage_usage_name.toLowerCase()
                                        let fb = b.manage_usage_name.toLowerCase()

                                        if (fa < fb) {
                                            return -1;
                                        }
                                        if (fa > fb) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                    for (var i = 0; i < usagesList.length; i++) {
                        if (usagesList[i].manage_usage_status) {
                            const row = {
                                value: usagesList[i].manage_usage_name,
                                label: usagesList[i].manage_usage_name
                            }
                            this.usageOptions.push(row)
                        }
                        
                    }

                    this.usageSelected = ""
                    
                } catch (e) {
                    console.log(e);
                }
            },

            getDetailUser(userID) {
                this.showLoading = true;
                try {
                    Service.getDetailUser("/users/"+userID)
                        .then((response) => {
                            this.showLoading = false;
                            let data = response.data
                            this.dataUser = data
                        })
                        .catch((error) => {
                            this.showLoading = false;
                            // console.log("Error : ", error.response.status);
                        });
                } catch (error) {
                    console.log("error: ", error);
                }
            },

            onCheckedClick(event, data) {
                // console.log('data', data, ' - event', event)
                let userID = this.$route.params.userId;
                let id = data.station_id
                let message = "Assign user "+userID+" ke "+data.station_name+ " telah ditambahkan."

                if (!event) {
                    message = "Assign user "+userID+" ke "+data.station_name+ " telah di remove."
                }

                if (data.checked) {
                    this.userGroups.push('bmkgentry_'+id)
                    if (!this.userGroups.includes("obsentry")) {
                        this.userGroups.push("obsentry")
                    }
                }
                else {
                    this.userGroups.forEach((group, index) => {
                        if(group == 'bmkgentry_'+id) {
                            this.userGroups.splice(index,1);
                            return false;
                        }
                    });
                }
                let post_data = { "user_groups": this.userGroups }

                console.log('post_data', post_data)

                this.showLoading = true;
                try {
                    Service.assignUserStation(userID, post_data)
                        .then((response) => {
                            this.showLoading = false;
                            let position = 'top-center'
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: message,
                                        icon: "BellIcon",
                                        variant: "success",
                                    },
                                },
                                {position}
                                );
                        })
                        .catch((error) => {
                            this.showLoading = false;
                            console.log("Error : ", error);
                        });
                } catch (error) {
                    console.log("error: ", error);
                }

            },

            onCheckedAllClick() {
                // console.log("onCheckedAllClick")
                let userID = this.$route.params.userId;
                let title = this.allSelected === 1 ? "Apakah Anda yakin untuk tidak assign user ke stasiun ?" : "Apakah Anda yakin untuk assign user ke semua stasiun ?"
                let message = this.allSelected === 1 ? "User "+userID+" tidak akan diassign ke stasiun dari list table" : "Assign user "+userID+" ke semua stasiun yang ada pada table"
                this.$swal({
                    title: title,
                    text: message,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        this.allSelected = this.allSelected === 1 ? 0 : 1
                        let position = 'top-center'
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Service ini dalam proses maintenance",
                                icon: "BellIcon",
                                variant: "warning",
                            },
                        },
                        {position},
                        );
                    } else if (result.dismiss === "cancel") {
                    return;
                    }
                });
            },

            async listAllPropinsi() {      
                // const { data: propinsi_data } = await metadata.getPropinsiList();
                
                // propinsi_data.forEach((item) => {
                //     const propinsi_item = {
                //         value: item.propinsi_id,
                //         text: item.propinsi_name,
                //     };
                //     this.propinsiOptions.push(propinsi_item);
                // });
                const { data: propinsi_data } = await metadata.getPropinsiList();
                let propinsiList = propinsi_data.sort((a, b) => {
                                        let fa = a.propinsi_name.toLowerCase()
                                        let fb = b.propinsi_name.toLowerCase()

                                        if (fa < fb) {
                                            return -1;
                                        }
                                        if (fa > fb) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                propinsiList.forEach((item) => {
                    const propinsi_item = {
                        value: item.propinsi_id,
                        label: item.propinsi_name,
                    };
                    this.propinsiOptions.push(propinsi_item);
                });
            },

            onPageChange(params) {
                // console.log('onPageChange', params)
                this.paginationCurrentPage = params.currentPage
                let startId = params.currentPage * params.currentPerPage
                let endId = startId + 200
                if (params.currentPage >= params.total &&  this.paramsStation == '') {
                    this.listAllStasiun(startId, endId, this.paramsStation, this.userType, this.ids, false)
                }
            },
            
            isStationID(event) {

                let text = this.searchText
                if (text === "") {
                    this.enabledSearch = true
                }
                if (!isNaN(text)) {

                    if (text.length >= 5) {
                        this.enabledSearch = true
                        return event.preventDefault();
                    }
                    else if (text.length == 5) {
                        this.enabledSearch = true
                    }
                    else {
                        this.enabledSearch = false
                    }
                }
                else {
                    this.enabledSearch = true
                }
            },


            isSearchTextEnabled(text) {

                if (text === "") {
                    return true
                }
                if (!isNaN(text)) {

                    if (text.length >= 5) {
                        return true
                    }
                    else if (text.length == 5) {
                        return true
                    }
                    else {
                        return false
                    }
                }
                else {
                    return true
                }
            },

            async listAllStasiun(startId, endId, options, userType, ids, isUsage) {
			
                this.isLoading = true
                try {
                    const { data } = await metadata.getAllStationWithParams(startId, endId, options, userType, ids, isUsage);
                    
                    this.isLoading = false

                    if (!Array.isArray(data)) {
                        this.rows.push(...data.items)
                    }
                    else {
                        data.forEach(function (item, index) {
                            let path = item.path;
                            if (path) {
                                item.path = path.split('usage')[0]
                            }
                        });
                        this.rows.push(...data)
                    }
                    // if (userType == 'root') {
                    //     this.rows.push(...data.items)
                    // }
                    // else {
                    //     this.rows.push(...data)
                    // }
                    
                    this.rows.sort(function(a, b){
                        return a.station_id - b.station_id
                    });

                    this.userGroups = this.dataUser.user_groups
                    for (var i = 0; i < this.rows.length; i++) {
                        let ischeck = false
                        if (this.userGroups) {
                            for (var n = 0 ; n < this.userGroups.length; n++) {
                                let sid = this.userGroups[n].split('_')
                                if (sid[0] == 'bmkgentry' &&  sid[1] == this.rows[i].station_id ){
                                    ischeck = true
                                    break
                                }
                            }
                        }
                        
                        this.rows[i]['checked'] = ischeck

                        // if (ischeck) {
                        //     if ( this.rowsSelected.indexOf(this.rows[i]) === -1) {
                        //         this.rowsSelected.push(this.rows[i])
                        //     }
                        // }
                        
                    }

                } catch (error) {
                    console.log('error', error)
                    if (error.hasOwnProperty('response') && error.response.hasOwnProperty('status') && error.response.status != undefined && error.response.status === 401) {
                        // this.$swal({
                        //         title: "Sesi anda telah habis.",
                        //         text: "Logout dan silahkan login kembali",
                        //         icon: "warning",
                        //         confirmButtonText: "OK",
                        //         allowOutsideClick: false,
                        //         allowEscapeKey: false,
                        //         customClass: {
                        //             confirmButton: "btn btn-primary",
                        //         },
                        //         buttonsStyling: false,
                        //     }).then((result) => {
                        //         localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                        //         localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
                        //         localStorage.removeItem('userData')
                        //         this.$router.push({ name: 'auth-login' })						
                        //     });
                    }
                }

                this.isLoading = false
            },

            searchMetadata() {
                
                // let usage = this.usageSelected
                // let propinsi = this.propinsiSelected
                let usage = (this.usageSelected !== null && this.usageSelected !== '') ? this.usageSelected.value : ''
			    let propinsi = (this.propinsiSelected !== null && this.propinsiSelected !== '') ? this.propinsiSelected.value : ''
                let search = this.searchText
                let isUsage = false

                this.paramsStation = ''

                // if (usage && usage != '') {
                //     isUsage = true
				//     this.paramsStation = "&usage_name="+usage+"&usage_status=true"
                // }
                if (usage && usage != '') {
                    // this.paramsStation = "&"+usage+"=true"
                    isUsage = true
                    this.paramsStation = "&usage_name="+usage+"&usage_status=true"
                    this.searchByUsage = (usage == "MKG") ? false : true
                }
                else {
                    this.searchByUsage = false
                }

                if (propinsi && propinsi != '') {
                    this.paramsStation = this.paramsStation + "&propinsi_id="+propinsi
                }

                if (search && search != '') {
                    let isStationName = isNaN(search)

                    if(isStationName) {
                        this.paramsStation = this.paramsStation  + "&station_name="+search
                    }
                    else {
                        this.paramsStation = this.paramsStation  + "&station_wmo_id="+search
                    }
                    
                }

                this.paginationCurrentPage = 1
                if (this.paramsStation != '') {
                    // console.log('this.paramsStation :', this.paramsStation)
                    this.rows = [];
                    this.listAllStasiun(0, 10000, this.paramsStation, this.userType, this.ids, isUsage);
                }
                else {
                    // console.log('this.paramsStation :', this.paramsStation)
                    this.rows = [];
                    this.listAllStasiun(0, 200, this.paramsStation, this.userType, this.ids, isUsage);
                }
                
            },

            getSelectedUsage: function(val) {
                this.enabledSearch = this.isSearchTextEnabled(this.searchText) ? true : false
            },

            getSelectedPropinsi: function(val) {
                this.enabledSearch = this.isSearchTextEnabled(this.searchText) ? true : false
            },
        }
    }

</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>

.vgt-table thead th.theadc {
    background-color: #f7fafc !important;
}

input.form-control {
    border: 1px solid #6e6b7b !important;
}

.b-form-datepicker.form-control {
    border: 1px solid #6e6b7b !important;
}

.custom-select {
    border: 1px solid #6e6b7b !important;
}
</style>