<template>
	<b-card no-body class="border mt-2">
		<a-spin :spinning="LOADING_TABLE" >
			<a-table :columns="COLUMN_HISTORY" :data-source="DATA_HISTORY">
				<span slot="description-column" slot-scope="nimos_sync_description">
					<div v-html="nimos_sync_description">
					</div>
					
				</span>
			</a-table>
		</a-spin>
	</b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormSelect,
  BTable,
  BButton,
  BFormCheckbox,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Service from "@/api/userservice";
export default {
	components: {
	BTab,
	BRow,
	BCol,
	BCard,
	BTabs,
	BFormGroup,
	BFormSelect,
	BTable,
	BButton,
	BFormCheckbox,
	BFormInput,
	vSelect,
	},
	props: ["profile", "type_form"],
	data() {
		return {
			LOADING_TABLE: false,
			COLUMN_HISTORY : [
				{
					title: 'DATE',
					dataIndex: 'log_date',
					key: 'log_date',
					scopedSlots: { customRender: "date-column" },
					width: 200
				},
				{
					title: 'USER',
					dataIndex: 'log_username',
					key: 'log_username',
					scopedSlots: { customRender: "username-column" },
					width: 200,
					align: 'center'
				},
				{
					title: 'MODULE',
					dataIndex: 'log_module',
					key: 'log_module',
					scopedSlots: { customRender: "module-column" },
					width: 200,
					align: 'center'
				},
				{
					title: 'ACTIVITY',
					dataIndex: 'log_activity',
					key: 'log_activity',
					scopedSlots: { customRender: "activity-column" },
					width: 200,
					align: 'center'
				},
				{
					title: 'DESCRIPTION',
					dataIndex: 'log_description',
					key: 'log_description',
					scopedSlots: { customRender: "description-column" },
				}
			],
			DATA_HISTORY : [],
			// fields: [
			// 	{
			// 		label: 'Date',
			// 		key: 'log_date',
			// 	},
			// 	{
			// 		label: 'User',
			// 		key: 'log_username',
			// 	},
			// 	{
			// 		label: 'Module',
			// 		key: 'log_module',
			// 	},
			// 	{
			// 		label: 'Activity',
			// 		key: 'log_activity',
			// 	},
			// 	{
			// 		label: 'Description',
			// 		key: 'log_description',
			// 	}
			// ],
			// items: [],
		};
	},
	mounted() {
		// console.log('type_form', this.type_form)
		let userID = this.$route.params.userId;
		this.getListLogActivity(userID)
	},
	methods: {
		getListLogActivity(userID) {  
			new Promise(async (resolve) => {
				this.LOADING_TABLE = true
				const{ data } = await Service.getListLogActivity(userID)
				let dataLog = data
				// console.log('getListLogActivity', data)
				for (let log of dataLog) {
					let id = log.id
					let dates = id.split('_')
					log['log_date'] = dates[1].split('T')[0] + " " + dates[1].split('T')[1] +":"+ dates[2] +":"+dates[3]
				}

				this.DATA_HISTORY = dataLog

				this.LOADING_TABLE = false
				resolve(data);
			});
		}
	},
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
