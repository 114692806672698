<template>
    <div class="mt-3">

        <!-- User Info: Input Fields -->
        <div class="ml-0">

            <b-row>
                <b-col cols="1">
                    <b-img
                        ref="previewEl"
                        rounded
                        :src="require('@/assets/images/avatars/user3.png')"
                        height="100"
                    />
                </b-col>

                <b-col cols="2" >
                    <div class=" ml-1 pl-2 pr-2">
                        <div>
                            <b-button
                                variant="primary"
                                class="w-100"
                            >
                                Update
                            </b-button>
                        </div>
                        <div>
                            <b-button
                                variant="outline-danger"
                                class="mt-1 w-100"
                            >
                                Remove
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
            
            <b-row class="mt-2 mb-1">
                <b-col class="mb-1">
                    <label>Username</label>
                    <b-form-input
                    type="text"
                    v-model="newUser.id"
                    readonly
                    ></b-form-input>
                </b-col>
                
                <b-col class="mb-1">
                    <label>Name</label>
                    <b-form-input
                    type="text"
                    v-model="newUser.name"
                    ></b-form-input>
                </b-col>
                
                <b-col class="mb-1">
                    <label>Email</label>
                    <b-form-input
                    type="text"
                    :state="validateState($v.newUser.email)"
					v-model="$v.newUser.email.$model"
                    ></b-form-input>
                </b-col>
                
            </b-row>
            
            <b-row class="mb-1">

                <b-col class="mb-1">
                    <label>Department</label>
                    <b-form-input
                    type="text"
                    v-model="newUser.department"
                    ></b-form-input>
                </b-col>

                <b-col class="mb-1">
                    <label>Start Date</label>
                    <b-form-datepicker
                    v-model="newUser.start_date"
                    locale="en"
                    placeholder="Pilih Tanggal"
                    
                    ></b-form-datepicker>
                </b-col>
                
                <b-col class="mb-1">
                    <label>End Date</label>
                    <b-form-datepicker
                    v-model="newUser.end_date"
                    locale="en"
                    placeholder="Pilih Tanggal"
                    ></b-form-datepicker>
                </b-col>
            </b-row>

            <b-row class="mb-1">
                <b-col class="mb-1">
                    <label>Role</label>
                    <b-form-select  v-model="newUser.role">
                    <option disabled value="">Pilihan</option>
                    <option v-for="(role, i) in roles" :key="i" :value="role">
                        {{ role }}
                    </option>
                    </b-form-select>
                </b-col>
                
                <b-col class="mb-1">
                    <label>New Password</label>
                    <b-form-input
                    type="password"
                    :state="validateState($v.newUser.password)"
					v-model="$v.newUser.password.$model"
                    ></b-form-input>
                </b-col>
                
                <b-col class="mb-1">
                    <label>New Password (again)</label>
                    <b-form-input
                    type="password"
                    :state="validateState($v.newUser.password_again)"
					v-model="$v.newUser.password_again.$model"
                    ></b-form-input>
                </b-col>
            </b-row>

           <b-row>
                <b-col>
                    <!-- Action Buttons -->
                    <b-card-footer class="float-right">
                        
                        <b-button
                            variant="gradient-danger"
                            class="mr-1"
                            @click="$router.push({ name: 'manage-users' })"
                        >
                        Cancel
                        </b-button>

                        <b-button
                            variant="gradient-primary"
                            class="mr-1"
                            @click="onSaveChanges"
                        >
                        Submit
                        </b-button>
                    </b-card-footer>
                </b-col>
           </b-row>

            <b-row>
                <b-col>
                    <div class="d-flex justify-content-between  flex-wrap mb-1">
                        <label>Assigned Stations</label>
                    </div>
                </b-col>
            </b-row>

            <b-row >
                <b-col>
                    <b-tabs >
                        <b-tab title="Selection">
                            <assign-selection />
                        </b-tab>
                        <b-tab title="Selected" lazy>
                            <assign-selected />
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
            
            
        </div>

        

        <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
    </div>
</template>

<script>
    import {
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BRow,
        BCol,
        BButton,
        BButtonGroup,
        BTable,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BFormDatepicker,
        BFormCheckbox,
        BFormCheckboxGroup,
        BOverlay,
        BImg,
        BTabs,
        BTab
    } from 'bootstrap-vue'
    import Service from "@/api/userservice";
    import RoleService from "@/api/rolepermissionservice";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { validationMixin } from "vuelidate";
    import { required, sameAs, minLength, email } from "vuelidate/lib/validators";
    import metadata from "@/api/metadata";
    import { VueGoodTable } from 'vue-good-table'
    import store from '@/store/index'
    import AssignSelection from './MUAssignSelection.vue'
    import AssignSelected from './MUAssignSelected.vue'
    export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BButton,
        BButtonGroup,
        BTable,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BFormDatepicker,
        BFormCheckbox,
        BFormCheckboxGroup,
        BOverlay,
        BImg,
        VueGoodTable,
        BTabs,
        BTab,
        AssignSelected,
		AssignSelection
    },
    data() {
        return {
            showLoading: false,
            roles: [],
            perPage: 5,
			pageOptions: [5, 10, 15],
			totalRows: 1,
			currentPage: 1,
			sortBy: '',
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
			filterOn: [],
            stationsTable: {
                fields: [{key:'id', label: 'Stasiun ID', sortable: true}, {key:'name', label: 'Nama Stasiun', sortable: true}, {key:'checked', label: 'Checked'}],
                items: [],
            },
            newUser: {
                id: "",
                name: "",
                email: "",
                department: "",
                role: "",
                start_date: "",
                end_date: "",
                password: "",
                password_again: "",
                assigned_stations: [],
            },
            userGroups: [],
            dataUser: '',
            isLoading: true,
            dir: false,
            userType:'root',
            paramsStation : '',
            paginationCurrentPage: 10,
			pageLength: 10,
            ids:'',
            rows: [],
            rowsSelected: [],
            columns: [
				{
					label: 'Station ID',
					field: 'station_wmo_id',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Station Name',
					field: 'station_name',
					tdClass: 'text-left',
					thClass: 'text-center theadc',
				},
				{
					label: 'Province',
					field: 'propinsi_name',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Checked',
					field: 'checked',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				}
			],
            propinsiSelected: '',
			propinsiOptions: [
				{ value: '', text: '-' }
			],
            usageSelected: '',
			usageOptions: [
				{ value: '', text: '-' },
				{ value: 'is_mkg', text: 'MKG' },
				{ value: 'is_poshujan', text: 'Pos Hujan' },
				{ value: 'is_kualitas_udara', text: 'Kualitas Udara' },
				{ value: 'is_peralatan_otomatis', text: 'AWS Center' },
				{ value: 'is_radar', text: 'Radar' },
				{ value: 'is_percepatan_tanah', text: 'Percepatan Tanah' },
				{ value: 'is_gempa', text: 'Gempa' },
				{ value: 'is_bmkgentry', text: 'BMKG Entry' },
			],
            enabledSearch: false
        };
    },

    computed: {
		direction() {
			if (store.state.appConfig.isRTL) {
				this.dir = true
				return this.dir
			}
			this.dir = false
			return this.dir
		},
	},

    mixins: [validationMixin],
	validations: {
		newUser: {
			password: {
				minLength: minLength(6),
			},
			password_again: {
				sameAsPassword: sameAs("password"),
			},
			email: {
				email
			}
		},
	},
    mounted() {
        let userID = this.$route.params.userId;
        // console.log('userID : ', userID)
        this.getDetailUser(userID)
        this.getListAllRole()
        // this.listAllPropinsi();

        // let userLogin = JSON.parse(localStorage.getItem('userLogin'))
		// this.userType = userLogin.username

		// if (userLogin.hasOwnProperty('groupIds')) {
		// 	this.ids = userLogin.groupIds
		// }

		// console.log('ids', this.ids)
		
		// this.rows = [];
        // this.rowsSelected = []
		// this.paramsStation = ''
		// this.listAllStasiun(1, 200, this.paramsStation, this.userType, this.ids);

    },
    methods: {

        onPageChange(params) {
			// console.log('onPageChange', params)
			this.paginationCurrentPage = params.currentPage
			let startId = params.currentPage * params.currentPerPage
			let endId = startId + 200
			if (params.currentPage >= params.total &&  this.paramsStation == '') {
				this.listAllStasiun(startId, endId, this.paramsStation, this.userType, this.ids)
			}
		},

        async listAllPropinsi() {      
			const { data: propinsi_data } = await metadata.getPropinsiList();
			
			propinsi_data.forEach((item) => {
				const propinsi_item = {
					value: item.propinsi_id,
					text: item.propinsi_name,
				};
				this.propinsiOptions.push(propinsi_item);
			});
		},

        async listAllStasiun(startId, endId, options, userType, ids) {
			
			this.isLoading = true
			try {
				const { data } = await metadata.getAllStationWithParams(startId, endId, options, userType, ids);
				
                this.isLoading = false

				if (userType == 'root') {
					this.rows.push(...data.items)
				}
				else {
					this.rows.push(...data)
				}
				
				this.rows.sort(function(a, b){
					return a.station_id - b.station_id
				});

                this.userGroups = this.dataUser.user_groups
                for (var i = 0; i < this.rows.length; i++) {
                    let ischeck = false
                    if (this.userGroups) {
                        for (var n = 0 ; n < this.userGroups.length; n++) {
                            let sid = this.userGroups[n].split('_')
                            if (sid[1] == this.rows[i].station_id ){
                                ischeck = true
                                break
                            }
                        }
                    }
                    
                    this.rows[i]['checked'] = ischeck

                    if (ischeck) {
                        if ( this.rowsSelected.indexOf(this.rows[i]) === -1) {
                            this.rowsSelected.push(this.rows[i])
                        }
                    }
                    
                }

			} catch (error) {
				console.log('error', error)
				if (error.hasOwnProperty('response') && error.response.hasOwnProperty('status') && error.response.status != undefined && error.response.status === 401) {
					// this.$swal({
					// 		title: "Sesi anda telah habis.",
					// 		text: "Logout dan silahkan login kembali",
					// 		icon: "warning",
					// 		confirmButtonText: "OK",
					// 		allowOutsideClick: false,
					// 		allowEscapeKey: false,
					// 		customClass: {
					// 			confirmButton: "btn btn-primary",
					// 		},
					// 		buttonsStyling: false,
					// 	}).then((result) => {
					// 		localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
					// 		localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
					// 		localStorage.removeItem('userData')
					// 		this.$router.push({ name: 'auth-login' })						
					// 	});
				}
			}

			this.isLoading = false
		},

        onFiltered(filteredItems) {
            console.log('onFiltered')
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        onCheckedClick(event, data) {
            console.log('data', data, ' - event', event)
            let userID = this.$route.params.userId;
            let id = data.station_id
            let message = "Assign user "+userID+" ke "+data.station_name+ " telah ditambahkan."

            if (!event) {
                message = "Assign user "+userID+" ke "+data.station_name+ " telah di remove."
            }

            if (data.checked) {
                this.userGroups.push('bmkgentry_'+id)
                if (!this.userGroups.includes("obsentry")) {
                    this.userGroups.push("obsentry")
                }
            }
            else {
                this.userGroups.forEach((group, index) => {
                    if(group == 'bmkgentry_'+id) {
                        this.userGroups.splice(index,1);
                        return false;
                    }
                });
            }
            let post_data = { "user_groups": this.userGroups }

            console.log('post_data', post_data)

            this.showLoading = true;
            try {
                Service.assignUserStation(userID, post_data)
                    .then((response) => {
                        this.showLoading = false;
                        let position = 'top-center'
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: message,
                                    icon: "BellIcon",
                                    variant: "success",
                                },
                            },
                            {position}
                            );
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        console.log("Error : ", error.response.status);
                    });
            } catch (error) {
                console.log("error: ", error);
            }

        },

        validateState(item) {
			const { $dirty, $error } = item;
			return $dirty ? !$error : null;
		},
        getDetailUser(userID) {
            this.showLoading = true;
            try {
                Service.getDetailUser("/users/"+userID)
                    .then((response) => {
                        this.showLoading = false;
                        // console.log("detail user: ", response.data);
                        // this.items = response.data;
                        let data = response.data
                        this.dataUser = data
                        this.newUser.id = userID//data.id
                        this.newUser.name = data.name ? data.name : data['@name']
                        this.newUser.email = data.email
                        this.newUser.password = ""
                        this.newUser.password_again = ""
                        
                        if (data.hasOwnProperty("properties")) {
                            if (data.properties.hasOwnProperty("department")) {
                                this.newUser.department = data.properties.department
                            }
                        }

                        let objDate = data['guillotina.behaviors.dublincore.IDublinCore']
                        this.newUser.start_date = objDate.creation_date
                        this.newUser.end_date = objDate.expiration_date


                        if(data.user_roles && data.user_roles.length > 0) {
                            for (var i = 0 ; i < data.user_roles.length; i++) {
                                let rolename = data.user_roles[i].split('.')
                                if(rolename[0] != 'guillotina') {
                                    this.newUser.role = rolename[1]
                                }
                            }
                        }
                        console.log('this.newUser.role', this.newUser.role)

                        // this.listAllStasiun()
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        console.log("Error : ", error);
                    });
            } catch (error) {
                console.log("error: ", error);
            }
        },
        onSaveChanges() {
            this.$v.$touch();
      		if (this.$v.$invalid) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Data tidak valid. Periksa kembali inputan anda",
						icon: "BellIcon",
						variant: "danger",
					},
				});
				return;
			}
			else {
                let post_data = {}
                if(this.newUser.password != '' && this.newUser.password_again != '') {
                    post_data = {
                        "name": this.newUser['name'],
                        "email": this.newUser.email,
                        "password":this.newUser.password,
                        "properties": {"department": this.newUser.department}
                    }
                    if(this.newUser.role != ""){
                        let post_roles = ["guillotina.Member"]
                        post_roles.push('bmkgsatu.'+this.newUser.role)
                        post_data = {
                            "name": this.newUser['name'],
                            "email": this.newUser.email,
                            "password":this.newUser.password,
                            "properties": {"department": this.newUser.department},
                            "user_roles": post_roles
                        }
                    }
                }
                else {
                    post_data = {
                        "name": this.newUser['name'],
                        "email": this.newUser.email,
                        "properties": {"department": this.newUser.department}
                    }

                    if(this.newUser.role != ""){
                        let post_roles = ["guillotina.Member"]
                        post_roles.push('bmkgsatu.'+this.newUser.role)
                        post_data = {
                            "name": this.newUser['name'],
                            "email": this.newUser.email,
                            "properties": {"department": this.newUser.department},
                            "user_roles": post_roles
                        }
                    }
                }

                if (this.newUser.role != '') {
                    post_data["user_roles"] = [
                        "guillotina.Member",
                        "bmkgsatu."+this.newUser.role
                    ]
                }

                if (this.newUser.end_date != "") {
                    post_data["guillotina.behaviors.dublincore.IDublinCore"] = {
                        "creation_date": this.newUser.start_date,
                        "expiration_date": this.newUser.end_date
                    }
                }

                console.log('postdata', post_data)
                
                this.showLoading = true;
                try {
                    Service.saveChanges("/users/"+this.newUser.id, post_data)
                    .then((response) => {
                        this.showLoading = false;
                        console.log("savechanges user: ", response);
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            console.log("success: ", response.statusText);
                            let position = 'top-center'
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Data sudah tersimpan",
                                    icon: "BellIcon",
                                    variant: "success",
                                },
                            },
                            {position}
                            );
                            this.$router.push({ name: "manage-users"});
                        }
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        if (error.response.status == 401) {
                            this.$swal({
                                title: "Unauthorized!",
                                text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }else {
                            this.$swal({
                                title: "Something wrong!",
                                text: ""+error.response.status,
                                icon: "warning",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                    });
                } catch (error) {
                    console.log("error: ", error);
                }
            }
        },

        getListAllRole() {
            this.showLoading = true;
			try {
				RoleService.getListAllRole()
                    .then((response) => {
                        this.showLoading = false;
                        // console.log("list-roles: ", response.data.items);

                        for (var i = 0; i< response.data.length; i++) {
                            let role_name = response.data[i]['rolename'].split('.')
                            this.roles.push(role_name[1])
                        }
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        console.log("Eee : ", error.response.status);
                    });
			} catch (error) {
				console.log("error: ", error);
				this.showLoading = false;
			}
        },

        isStationID(event) {

			let text = this.searchText
			if (text === "") {
				this.enabledSearch = true
			}
			if (!isNaN(text)) {

				if (text.length >= 5) {
					this.enabledSearch = true
					return event.preventDefault();
				}
				else if (text.length == 5) {
					this.enabledSearch = true
				}
				else {
					this.enabledSearch = false
				}
			}
			else {
				this.enabledSearch = true
			}
		},

        searchMetadata() {
			
			let usage = this.usageSelected
			let propinsi = this.propinsiSelected
			let search = this.searchText

			this.paramsStation = ''

			if (usage && usage != '') {
				this.paramsStation = "&"+usage+"=true"
			}

			if (propinsi && propinsi != '') {
				this.paramsStation = this.paramsStation + "&propinsi_id="+propinsi
			}

			if (search && search != '') {
				let isStationName = isNaN(search)

				if(isStationName) {
					this.paramsStation = this.paramsStation  + "&station_name="+search
				}
				else {
					this.paramsStation = this.paramsStation  + "&station_wmo_id="+search
				}
				
			}

			if (this.paramsStation != '') {
				// console.log('this.paramsStation :', this.paramsStation)
				this.rows = [];
				this.listAllStasiun(0, 10000, this.paramsStation, this.userType, this.ids);
			}
			else {
				// console.log('this.paramsStation :', this.paramsStation)
				this.rows = [];
				this.listAllStasiun(0, 200, this.paramsStation, this.userType, this.ids);
			}
			
		},

        getSelectedUsage: function(val) {
			this.enabledSearch = this.isSearchTextEnabled(this.searchText) ? true : false
		},

		getSelectedPropinsi: function(val) {
			this.enabledSearch = this.isSearchTextEnabled(this.searchText) ? true : false
		},
    }
}
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>

.vgt-table thead th.theadc {
    background-color: #f7fafc !important;
}

input.form-control {
    border: 1px solid #6e6b7b !important;
}

.b-form-datepicker.form-control {
    border: 1px solid #6e6b7b !important;
}

.custom-select {
    border: 1px solid #6e6b7b !important;
}
</style>